<template>
  <div v-if="testimony && testimony.content" class="sb-testimonies-tabs">
    <div class="sb-testimonies-tabs-container">
      <div class="sb-testimonies-tabs-title">
        <span>{{ $t('proArea.testimonies') }}</span>
      </div>

      <div class="sb-testimonies-tabs-content u-d-f">
        <div class="sb-testimonies-tabs-content-left">
          <div class="sb-testimonies-tabs-content-left-img">
            <ImageLazy :src="testimony.content.picture" />
          </div>
        </div>

        <div class="sb-testimonies-tabs-content-right">
          <div class="sb-testimonies-tabs-content-right-title">
            <span>{{ testimony.content.title }}</span>
          </div>

          <a
            v-if="testimony.content.espace_pro_service"
            class="sb-testimonies-tabs-content-right-product u-d-f u-fw-wrap-sm u-ai-c"
            :href="`/services/${getStoryByUid(testimony.content.espace_pro_service) ? getStoryByUid(testimony.content.espace_pro_service).slug : null}`"
          >
            <template
              v-if="
                getStoryByUid(testimony.content.espace_pro_service) &&
                getStoryByUid(testimony.content.espace_pro_service).content
              "
            >
              <div
                v-if="
                  getStoryByUid(testimony.content.espace_pro_service).content.icon &&
                  getStoryByUid(testimony.content.espace_pro_service).content.icon.filename
                "
                class="sb-testimonies-tabs-content-right-product-icon"
              >
                <ImageLazy :src="getStoryByUid(testimony.content.espace_pro_service).content.icon.filename" />
              </div>

              <div class="sb-testimonies-tabs-content-right-product-name">
                <span>{{
                  getStoryByUid(testimony.content.espace_pro_service).content.alternate_title ||
                  getStoryByUid(testimony.content.espace_pro_service).content.title
                }}</span>
              </div>
            </template>
          </a>
          <div
            v-else-if="testimony.content.service"
            class="sb-testimonies-tabs-content-right-product u-d-f u-fw-wrap-sm u-ai-c"
          >
            <div v-if="testimony.content.service_icon" class="sb-testimonies-tabs-content-right-product-icon">
              <ImageLazy :src="testimony.content.service_icon.filename" />
            </div>

            <div class="sb-testimonies-tabs-content-right-product-name">
              <span>{{ testimony.content.service }}</span>
            </div>
          </div>

          <div class="hide-xs">
            <div class="sb-testimonies-tabs-content-right-testimony">
              <span>{{ testimony.content.text }}</span>
            </div>

            <div class="sb-testimonies-tabs-button-list u-d-f u-fw-wrap-md">
              <main-button
                v-for="(tb, idx) in currentTestimonies"
                :key="idx"
                :label="`${tb.content.firstname}${tb.content.role ? `, ${tb.content.role}` : ''}`"
                tag="button"
                class="sb-testimonies-tabs-button-list-button"
                :class-variant="[
                  'min',
                  'hide-label-md',
                  tb === testimony ? 'btn-primary-dark-blue' : 'btn-dark-blue-white',
                ]"
                @click="selectTestimony(tb)"
              >
                <template #iconRight>
                  <div class="sb-testimonies-tabs-button-list-button-icon">
                    <ImageLazy :src="tb.content.picture" />
                  </div>
                </template>
              </main-button>
            </div>
          </div>
        </div>
      </div>
      <div class="show-xs u-p-t-md">
        <div class="sb-testimonies-tabs-content-right-testimony">
          <span>{{ testimony.content.text }}</span>
        </div>

        <div class="sb-testimonies-tabs-button-list u-d-f u-fw-wrap-md">
          <main-button
            v-for="(tb, idx) in currentTestimonies"
            :key="idx"
            :label="tb.buttonText"
            tag="button"
            class="sb-testimonies-tabs-button-list-button"
            :class-variant="[
              'min',
              'hide-label-md',
              tb === testimony ? 'btn-primary-dark-blue' : 'btn-dark-blue-white',
            ]"
            @click="selectTestimony(tb)"
          >
            <template #iconRight>
              <div v-if="tb && tb.content" class="sb-testimonies-tabs-button-list-button-icon">
                <ImageLazy :src="tb.content.picture" />
              </div>
            </template>
          </main-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'SbTestimoniesTabs',
  components: {
    MainButton,
  },
  props: {
    testimonies: { type: Array, default: () => [] },
  },
  data() {
    return {
      testimony: null,
    }
  },
  computed: {
    ...mapGetters('proArea', ['getTestimonyByUid', 'getStoryByUid']),
    currentTestimonies() {
      return this.testimonies.map(x => this.getTestimonyByUid(x))
    },
  },
  mounted() {
    this.testimony = this.currentTestimonies[0]
  },
  methods: {
    selectTestimony(testimony) {
      this.testimony = testimony
    },
  },
}
</script>

<style lang="scss">
.sb-testimonies-tabs {
  padding: $spacing-xl;
  background-color: var(--dark-blue);

  @include mq($mq-xs) {
    padding: $spacing-md;
  }

  &-container {
    max-width: 1000px;
    margin: 0 auto;
  }

  &-title {
    font-weight: 800;
    font-size: pxToRem(25px);
    color: var(--white);
    padding-bottom: $spacing-xl;
    @include mq($mq-sm) {
      font-size: pxToRem(20px);
    }
  }

  &-content {
    gap: $spacing-md;

    &-left {
      &-img {
        width: 150px;
        height: 150px;
        border-radius: 10px;
        overflow: hidden;
        border-radius: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &-right {
      &-title {
        font-weight: 800;
        font-size: pxToRem(20px);
        color: var(--white);
        padding-bottom: $spacing-sm;
        @include mq($mq-sm) {
          font-size: pxToRem(15px);
        }
      }

      &-product {
        padding-bottom: $spacing-md;
        text-decoration: none;

        &-icon {
          width: 38px;
          height: 38px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-name {
          font-weight: 400;
          font-size: pxToRem(15px);
          color: var(--white);
        }
      }

      &-testimony {
        font-weight: 400;
        font-size: pxToRem(15px);
        color: var(--white);
        padding-bottom: $spacing-md;
      }
    }
  }

  &-button-list {
    &-button {
      &-icon {
        width: 28px;
        height: 28px;
        margin-left: $spacing-sm;
        border-radius: 30px;
        overflow: hidden;

        @include mq($mq-sm) {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          margin-left: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @include mq($mq-sm) {
        width: 44px;
        height: 44px;
        border-radius: 25px;
        margin-left: 0;
      }
    }
  }
}
</style>
